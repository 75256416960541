import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import Collections from "./components/Collections.jsx";
import VeiledReverie from "./components/VeiledReverie.jsx";
import BlossomingTraditions from "./components/BlossomingTraditions.jsx";
import EternalRythm from "./components/EternalRythm.jsx";
import Publication from "./components/Publication.jsx";
import Pigeon from "./components/Pigeon.jsx";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/collections" element={<Collections />} />
          <Route path="/veiledreverie" element={<VeiledReverie />} />
          <Route
            path="/blossomingtraditions"
            element={<BlossomingTraditions />}
          />
          <Route path="/eternalrythm" element={<EternalRythm />} />
          <Route path="/publication" element={<Publication />} />
          <Route path="/flight-of-longing" element={<Pigeon />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
