import React, { useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BrowserView } from "react-device-detect";
import Footer from "./Footer";
import GalleryModal from "./GalleryModal"; // Import the GalleryModal component

function BlossomingTraditions() {
  const parallaxRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const images = [
    "/assets/blossoming-traditions/01.webp",
    "/assets/blossoming-traditions/02.webp",
    "/assets/blossoming-traditions/03.webp",
    "/assets/blossoming-traditions/04.webp",
    "/assets/blossoming-traditions/05.webp",
    "/assets/blossoming-traditions/06.webp",
  ];
  return (
    <Box className="collection-page-padding">
      <Parallax pages={isMobile ? 3 : 5} ref={parallaxRef}>
        <ParallaxLayer offset={0} factor={0.5}>
          <Box className="collection-header">
            <Typography
              className="h2-variant"
              variant="h2"
              sx={{ padding: "0 16px" }}
            >
              BLOSSOMING <br /> TRADITIONS
            </Typography>

            <Link to="/">
              <img src="/assets/close.svg" alt="" />
            </Link>
          </Box>

          <Box className="collection-header-info">
            <Typography variant="body1">[COLLECTION 3]</Typography>

            <Typography
              onClick={openModal}
              variant="body1"
              sx={{
                display: "flex",
                alignItems: "center",
                color: "black",
                cursor: "pointer",
              }}
            >
              VIEW GALLERY <ArrowForwardIcon />
            </Typography>
          </Box>
        </ParallaxLayer>

        <ParallaxLayer
          offset={isMobile ? 0.6 : 0.8}
          sticky={isMobile ? false : { start: 0.8, end: 4 }}
          style={{
            textAlign: "left",
            width: "28%",
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            zIndex: "-5",
            padding: "0 16px",
          }}
          className="collection-info"
        >
          <Typography
            variant="body1"
            fontWeight={600}
            className="collection-info-title"
          >
            INFO
          </Typography>
          <Typography variant="body1">
            Introducing our exclusive collection, “Blossoming Traditions,” where
            the ancient art of Khayameya seamlessly meets contemporary style.
            These handcrafted bucket hats, adorned with intricate floral Islamic
            motifs, are a testament to our commitment to preserving and
            celebrating the rich craft.
          </Typography>
          <Typography variant="body1">
            In collaboration with skilled craftsmen, we’ve delicately translated
            traditional Khayameya art into a wearable masterpiece. Our bucket
            hat series is a fusion of heritage and fashion, ensuring that the
            art not only endures but flourishes in the form of a timeless
            accessory. Each hat tells a story of meticulous craftsmanship,
            turning traditional motifs into a symbol of enduring elegance.
          </Typography>
        </ParallaxLayer>

        <ParallaxLayer
          offset={1.2}
          style={{
            display: isMobile ? "" : "none",
            justifyContent: "center",
          }}
        >
          <Carousel
            showArrows={true}
            showIndicators={false}
            dots={true}
            infiniteLoop={true}
            showThumbs={false}
            autoPlay={true}
            emulateTouch={true}
            showStatus={true}
            style={{ padding: "0 16px" }}
          >
            {images.map((image, index) => (
              <div key={index}>
                <img
                  style={{ width: "90%", height: "500px", objectFit: "cover" }}
                  src={image}
                  alt=""
                />
              </div>
            ))}
          </Carousel>
        </ParallaxLayer>

        <BrowserView>
          <ParallaxLayer
            offset={0.8}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/blossoming-traditions/01.webp"
              alt=""
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={1}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/blossoming-traditions/02.webp"
              alt=""
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={1.8}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/blossoming-traditions/03.webp"
              alt=""
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={2}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/blossoming-traditions/04.webp"
              alt=""
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={2.8}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/blossoming-traditions/05.webp"
              alt=""
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={3}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/blossoming-traditions/06.webp"
              alt=""
            />
          </ParallaxLayer>
        </BrowserView>

        <ParallaxLayer
          offset={isMobile ? 2 : 4}
          style={{
            background: "#f0efe5",
          }}
        >
          <Footer parallaxRef={parallaxRef} />
        </ParallaxLayer>
      </Parallax>

      <GalleryModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        images={images}
      />
    </Box>
  );
}

export default BlossomingTraditions;
