import React from "react";
import { Box, Typography, Button } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

function Footer({ parallaxRef }) {
  // Function to scroll to the top
  const scrollToTop = () => {
    parallaxRef.current.scrollTo(0);
  };

  return (
    <Box className="footer">
      <Button
        onClick={scrollToTop}
        startIcon={<ArrowUpwardIcon />}
        style={{
          position: "fixed",
          top: "20px",
          right: "20px",
          color: "#6B6B6B",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Back to Top
      </Button>
      <Typography variant="h4">
        CRAFTING HANDMADE{" "}
        <span style={{ fontFamily: "Ogg Italic" }}>tapesteries</span>
        <br />
      </Typography>
      <Typography variant="h4">
        TO DELIVER THE ART OF{" "}
        <span style={{ fontFamily: "Ogg Italic" }}>khayameya.</span> <br />
      </Typography>
      <Typography variant="h4">
        PURCHASE UPON REQUEST{" "}
        <FiberManualRecordIcon sx={{ fontSize: "12px" }} />{" "}
        <span style={{ fontFamily: "Ogg Italic" }}>Based in</span> CAIRO, EGYPT{" "}
        <br />
      </Typography>
      <Typography variant="h4">
        <span style={{ fontFamily: "Ogg Italic" }}>Follow</span> INSTAGRAM{""}
        <FiberManualRecordIcon sx={{ fontSize: "12px" }} /> BEHANCE <br />
      </Typography>
      <Typography variant="h4">
        <span style={{ fontFamily: "Ogg Italic" }}>Contact</span> (+20)
        01099976076 <FiberManualRecordIcon sx={{ fontSize: "12px" }} />{" "}
        habibasawaff@gmail.com <br />
      </Typography>

      <Typography
        variant="h5"
        sx={{
          position: "absolute",
          bottom: "1%",
          right: "2%",
          textAlign: "left",
          fontSize: "10px",
          color: "#6B6B6B",
        }}
      >
        SITE BY <br />
        <span
          style={{ color: "black", fontFamily: "Suiss Bold", fontSize: "14px" }}
        >
          GABEDESIGN
        </span>
      </Typography>
    </Box>
  );
}

export default Footer;
