import React from "react";
import { Box, Typography } from "@mui/material";
import { BrowserView, MobileView } from "react-device-detect";

function Exhibitions({ className }) {
  return (
    <Box
      className={`homepage-collections ${className}`}
      sx={{ background: "#f0efe5" }}
    >
      <BrowserView>
        <Box className="sections-title">
          <Typography variant="body1" color="black">
            // 02
          </Typography>
          <Typography variant="body1">2024</Typography>
          <Typography variant="body1">EXHIBITIONS</Typography>
          <Typography variant="body1">ATTENDED EVENTS</Typography>
        </Box>
      </BrowserView>

      <MobileView>
        <Box className="sections-title">
          <Box textAlign={"left"}>
            <Typography variant="body1" color="black">
              // 02
            </Typography>
            <Typography variant="body1">2024</Typography>
          </Box>

          <Box textAlign={"right"}>
            <Typography variant="body1">EXHIBITIONS</Typography>
            <Typography variant="body1">ATTENDED EVENTS</Typography>
          </Box>
        </Box>
      </MobileView>

      <Box className="collections-content">
        <Box className="collection-content-title">
          <Typography variant="body1">APRIL 2022 // CAIRO, EGYPT</Typography>

          <Typography variant="h4">Bayt Yakan</Typography>
        </Box>
        <hr style={{ margin: "2% 0" }} />

        <Box className="collection-content">
          <img src="/assets/collections/content-1.png" alt="Bayt Yakan" />
          <Typography variant="body1" fontSize={"16px"} marginTop={5}>
            This exhibition was a Khayameya Pop- Up curated in Bayt Yakan by
            Seif El- Rashidi. Bayt Yakan is a historical site in Cairo that was
            originally a privately- renovated 17th century house located in Darb
            el Labbana.
          </Typography>
        </Box>
      </Box>

      <Box className="collections-content">
        <Box className="collection-content-title">
          <Typography variant="body1">MARCH 2024 // CAIRO, EGYPT</Typography>

          <Typography variant="h4">Bibliohek Art Gallery</Typography>
        </Box>
        <hr style={{ margin: "2% 0" }} />

        <Box className="collection-content">
          <img src="/assets/collections/content-2.png" alt="Bayt Yakan" />
          <Typography variant="body1" fontSize={"16px"} marginTop={5}>
            This exhibition of Khayameya focuses on old and new, capturing the
            spirit of this remarkable craft, demonstrating how it continues to
            invent itself responding to the ways in which Egyptian society
            itself evolves.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Exhibitions;
