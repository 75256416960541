import React, { useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import Footer from "./Footer";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BrowserView } from "react-device-detect";
import HomeVideo from "./HomeVideo";

import GalleryModal from "./GalleryModal"; // Import the GalleryModal component

const images = [
  "/assets/veiled-reverie/01.webp",
  "/assets/veiled-reverie/02.webp",
  "/assets/veiled-reverie/03.webp",
  "/assets/veiled-reverie/04.webp",
  "/assets/veiled-reverie/05.webp",
  "/assets/veiled-reverie/06.webp",
  "/assets/veiled-reverie/07.webp",
  "/assets/veiled-reverie/08.webp",
  "/assets/veiled-reverie/09.webp",
  "/assets/veiled-reverie/10.webp",
  "/assets/veiled-reverie/11.webp",
  "/assets/veiled-reverie/12.webp",
  "/assets/veiled-reverie/13.webp",
  "/assets/veiled-reverie/14.webp",
];

function VeiledReverie() {
  const parallaxRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box className="collection-page-padding">
      <Parallax pages={isMobile ? 7.5 : 13} ref={parallaxRef}>
        <ParallaxLayer offset={0} factor={0.5}>
          <Box className="collection-header">
            <Typography variant="h2" sx={{ padding: "0 16px" }}>
              VEILED <br /> REVERIE
            </Typography>

            <Link to="/">
              <img src="/assets/close.svg" alt="" />
            </Link>
          </Box>

          <Box className="collection-header-info">
            <Typography variant="body1">[COLLECTION 1]</Typography>

            <Typography
              onClick={openModal}
              variant="body1"
              sx={{
                display: "flex",
                alignItems: "center",
                color: "black",
                cursor: "pointer",
              }}
            >
              VIEW GALLERY <ArrowForwardIcon />
            </Typography>
          </Box>
        </ParallaxLayer>

        <ParallaxLayer
          offset={0.7}
          sticky={isMobile ? false : { start: 0.7, end: 19 }}
          style={{
            textAlign: "left",
            width: "28%",
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            zIndex: "-5",
            padding: "0 16px",
          }}
          className="collection-info"
        >
          <Typography
            className="collection-info-title"
            variant="body1"
            fontWeight={600}
          >
            INFO
          </Typography>
          <Typography variant="body1">
            Our collection, “Veiled Reverie” is a series of three Khayameyas
            reimagines traditional motifs, transforming them into mesmerizing
            illusion patterns. Delve into the hidden beauty beneath the surface
            as each tapestry invites you to see through the craft, revealing the
            meticulous detailing and years of practiced expertise.
          </Typography>
          <Typography variant="body1">
            Uncover a stitched poem adorning each tapestry, harkening back to
            the days when these masterpieces carried verses and texts. “Veiled
            Reverie” invites you to appreciate the artistry, experience, and
            inspiration woven into every stitch—a tribute to the artisan’s love
            for the craft. Journey through the eyes of the skilled hands that
            intricately sew each needle, and let the hidden verses speak to your
            soul.
          </Typography>
        </ParallaxLayer>

        <ParallaxLayer
          offset={1.2}
          style={{
            display: isMobile ? "" : "none",
            justifyContent: "center",
          }}
        >
          <Carousel
            showArrows={true}
            showIndicators={false}
            dots={true}
            infiniteLoop={true}
            showThumbs={false}
            autoPlay={true}
            emulateTouch={true}
            showStatus={true}
            style={{ padding: "0 16px" }}
          >
            {images.map((image, index) => (
              <div key={index}>
                <img
                  style={{ width: "95%", height: "500px", objectFit: "cover" }}
                  src={image}
                  alt=""
                />
              </div>
            ))}
          </Carousel>
        </ParallaxLayer>

        <BrowserView>
          <ParallaxLayer
            offset={0.8}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/veiled-reverie/01.webp"
              alt=""
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={1}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/veiled-reverie/02.webp"
              alt=""
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={1.8}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/veiled-reverie/03.webp"
              alt=""
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={2}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/veiled-reverie/04.webp"
              alt=""
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={2.8}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/veiled-reverie/05.webp"
              alt=""
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={3}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/veiled-reverie/06.webp"
              alt=""
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={3.8}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/veiled-reverie/07.webp"
              alt=""
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={4}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/veiled-reverie/08.webp"
              alt=""
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={4.8}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/veiled-reverie/09.webp"
              alt=""
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={5}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/veiled-reverie/10.webp"
              alt=""
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={5.8}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/veiled-reverie/11.webp"
              alt=""
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={6}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/veiled-reverie/12.webp"
              alt=""
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={6.8}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/veiled-reverie/13.webp"
              alt=""
            />
          </ParallaxLayer>
        </BrowserView>

        <ParallaxLayer
          offset={isMobile ? 1.9 : 7}
          style={{
            textAlign: "left",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: isMobile ? "100%" : "40%",
              padding: isMobile ? "16px" : "16px",
              marginLeft: isMobile ? "" : "20%",
              display: "flex",
              flexDirection: "column",
              gap: "30px",
            }}
          >
            <Typography variant="body1" fontWeight={600}>
              Concept
            </Typography>
            <Typography variant="body1">
              The three designs draw inspiration from the traditional elements
              inherent in Khayamiya designs, abstracting and employing them to
              reveal the intricate layers of the craft.
            </Typography>
            <Typography variant="body1">
              Drawing inspiration from the versatile designs of tapestries in
              Khayameya Street, the design process involved experimenting with
              unconventional color combinations and variations to evoke the
              intended sense of depth and mesmerizing effect that the craft
              instills in both the observer and the creator.
            </Typography>

            <img
              src="/assets/veiled-reverie/elements.png"
              alt="elements"
              style={{ width: "100%" }}
            />

            <img
              src="/assets/veiled-reverie/hands.png"
              alt="crafting"
              style={{ width: "100%" }}
            />
          </Box>
        </ParallaxLayer>

        <ParallaxLayer
          offset={isMobile ? 2.8 : 8}
          style={{
            textAlign: "left",
            display: "flex",
            justifyContent: "center",
            zIndex: "100",
            backgroundColor: "#fffef3",
            padding: "16px",
          }}
        >
          <Box
            sx={{
              width: isMobile ? "100%" : "40%",
              padding: isMobile ? "16px" : "16px",
              marginLeft: isMobile ? "" : "20%",
              display: "flex",
              flexDirection: "column",
              gap: "30px",
            }}
          >
            <Typography variant="body1" fontWeight={600}>
              THE EPIGRAM
            </Typography>
            <Typography variant="body1">
              Motivated by the ancient epigrams once inscribed on old
              Khayamiyas, featuring colloquial sayings from the streets, I
              endeavored to revive this tradition. Collaborating closely with
              the craftsmen, we crafted a stitched poem to embellish each
              tapestry, reminiscent of an era when these masterpieces bore
              verses and texts.
            </Typography>
            <Typography variant="body1">
              The poem was composed in tandem with the artisans as they worked
              on the pieces, with its stitching reserved as the final step, thus
              honoring the heritage and craftsmanship inherent in these
              creations. In the poem, each of the three verses addresses a
              distinct theme connected to the craft: the first verse explores
              the concept of the craft's emergence, the second verse delves into
              the artisans and their legacy, while the third verse contemplates
              the current status and future prospects of the craft.
            </Typography>

            <img
              src="/assets/veiled-reverie/sketches.png"
              alt="elements"
              style={{ width: "100%" }}
            />
          </Box>
        </ParallaxLayer>

        <ParallaxLayer
          offset={isMobile ? 4 : 9}
          speed={0.5}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <img
            style={{
              width: isMobile ? "90%" : "60%",
              height: isMobile ? "60%" : "60%",
              padding: isMobile ? "0 5%" : "",
              objectFit: "cover",
            }}
            src="/assets/veiled-reverie/14.webp"
            alt=""
          />
        </ParallaxLayer>

        <ParallaxLayer
          offset={isMobile ? 4.9 : 10}
          speed={0.5}
          style={{
            width: isMobile ? "300%" : "",
            height: isMobile ? "300%" : "",
            marginLeft: isMobile ? "-100%" : "",
          }}
        >
          <HomeVideo videoId="945969363" />
        </ParallaxLayer>

        <ParallaxLayer
          offset={isMobile ? 5.5 : 11}
          style={{
            textAlign: "left",
            display: "flex",
            justifyContent: "center",
            padding: "16px",
          }}
        >
          <Box
            sx={{
              width: isMobile ? "100%" : "40%",
              padding: isMobile ? "16px" : "16px",
              marginLeft: isMobile ? "" : "20%",
              display: "flex",
              flexDirection: "column",
              gap: "30px",
            }}
          >
            <Typography variant="body1" fontWeight={600}>
              CREDITS
            </Typography>
            <Typography variant="body1">
              This project was created alongside the skilled artists and
              craftsmen: Mostafa El Leithy, Afef Fatouh,&  Mohamed Fathouh.
            </Typography>
            <Typography variant="body1">
              Credits to Dr.Seif El Rashidi for his input and images archive.
              This project was done under the supervision of Amparo Baquerizas.
            </Typography>
            <Typography variant="body1">
              Shot in Bab Zuweila, Darb El Ahmar
            </Typography>
          </Box>
        </ParallaxLayer>

        <ParallaxLayer
          offset={isMobile ? 6.5 : 12}
          style={{
            background: "#f0efe5",
          }}
        >
          <Footer parallaxRef={parallaxRef} />
        </ParallaxLayer>
      </Parallax>

      <GalleryModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        images={images}
      />
    </Box>
  );
}

export default VeiledReverie;
