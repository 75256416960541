import React, { useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import Footer from "./Footer";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BrowserView } from "react-device-detect";
import GalleryModal from "./GalleryModal"; // Import the GalleryModal component

function EternalRythm() {
  const parallaxRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const images = [
    "/assets/eternal-rythm/01.webp",
    "/assets/eternal-rythm/02.webp",
    "/assets/eternal-rythm/03.webp",
  ];
  return (
    <Box className="collection-page-padding">
      <Parallax pages={isMobile ? 4.5 : 6} ref={parallaxRef}>
        <ParallaxLayer offset={0} factor={1}>
          <Box className="collection-header eternal-rythm">
            <Box>
              <img
                src="/assets/eternal-rythm/arabic.png"
                alt="arabic"
                className="arabic"
                style={{
                  marginBottom: "-100px",
                }}
              />

              <Typography variant="h2" sx={{ padding: "0 16px" }}>
                ETERNAL RYHTM
              </Typography>
            </Box>

            <Link to="/">
              <img
                src="/assets/close.svg"
                alt=""
                style={{ marginBottom: "0 !important" }}
              />
            </Link>
          </Box>

          <Box className="collection-header-info">
            <Typography variant="body1">[COLLECTION 3]</Typography>

            <Typography
              onClick={openModal}
              variant="body1"
              sx={{
                display: "flex",
                alignItems: "center",
                color: "black",
                cursor: "pointer",
              }}
            >
              VIEW GALLERY <ArrowForwardIcon />
            </Typography>
          </Box>
        </ParallaxLayer>

        <ParallaxLayer
          offset={isMobile ? 0.6 : 0.8}
          sticky={isMobile ? false : { start: 0.8, end: 19 }}
          style={{
            textAlign: "left",
            width: "28%",
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            zIndex: "-5",
            padding: "0 16px",
          }}
          className="collection-info"
        >
          <Typography
            variant="body1"
            fontWeight={600}
            className="collection-info-title"
          >
            INFO
          </Typography>
          <Typography variant="body1">
            Harmony transcends matter. The eye is a witness of the bond between
            the stitches and the chords that play to the beat of the moving
            particles. One stitch after the other, paving the way for an eternal
            rhythm that hums its way through the hues of day; eternally
            existing.
          </Typography>
        </ParallaxLayer>

        <ParallaxLayer
          offset={1}
          style={{
            display: isMobile ? "" : "none",
            justifyContent: "center",
          }}
        >
          <Carousel
            showArrows={true}
            showIndicators={false}
            dots={true}
            infiniteLoop={true}
            showThumbs={false}
            autoPlay={true}
            emulateTouch={true}
            showStatus={true}
            style={{ padding: "0 16px" }}
          >
            {images.map((image, index) => (
              <div key={index}>
                <img
                  style={{ width: "90%", height: "500px", objectFit: "cover" }}
                  src={image}
                  alt=""
                />
              </div>
            ))}
          </Carousel>
        </ParallaxLayer>

        <BrowserView>
          <ParallaxLayer
            offset={0.8}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "100%", objectFit: "cover" }}
              src="/assets/eternal-rythm/01.webp"
              alt=""
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={1.4}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "100%", objectFit: "cover" }}
              src="/assets/eternal-rythm/02.webp"
              alt=""
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={2}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "100%", objectFit: "cover" }}
              src="/assets/eternal-rythm/03.webp"
              alt=""
            />
          </ParallaxLayer>
        </BrowserView>

        <ParallaxLayer
          offset={isMobile ? 1.9 : 3}
          style={{
            textAlign: "left",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: isMobile ? "100%" : "40%",
              padding: isMobile ? "16px" : "16px",
              marginLeft: isMobile ? "" : "20%",
              display: "flex",
              flexDirection: "column",
              gap: "30px",
            }}
          >
            <Typography variant="body1" fontWeight={600}>
              Concept
            </Typography>
            <Typography variant="body1">
              The rabbit was a prevalent motif in the art of the Egyptian
              Fatimid era, often depicted with lively movement on pottery and
              other artifacts. This collection  draws inspiration from this
              tradition, reflecting on the harmony and unity within our culture
              and traditions. The artwork symbolizes how these elements
              harmoniously intertwine, akin to music, giving life to everything
              around them, much like the dynamic portrayal of the rabbit.
            </Typography>
            <Typography variant="body1">
              To illustrate this concept, the two series of tapestries are
              linked by the rabbit motif. In the initial piece, fragments of the
              rabbit are depicted confined within vases, while in the subsequent
              piece, these fragments amalgamate to form a unified entity,
              representing the convergence of the eternal rhythm.
            </Typography>

            <img
              src="/assets/eternal-rythm/elements.png"
              alt="elements"
              style={{ width: "100%" }}
            />
          </Box>
        </ParallaxLayer>

        <ParallaxLayer
          offset={isMobile ? 3 : 4}
          style={{
            textAlign: "left",
            display: "flex",
            justifyContent: "center",
            padding: "16px",
          }}
        >
          <Box
            sx={{
              width: isMobile ? "100%" : "40%",
              padding: isMobile ? "16px" : "16px",
              marginLeft: isMobile ? "" : "20%",
              display: "flex",
              flexDirection: "column",
              gap: "30px",
            }}
          >
            <Typography variant="body1" fontWeight={600}>
              CREDITS
            </Typography>
            <Typography variant="body1">
              This project was created alongside the skilled artists and
              craftsmen Mostafa El-Leithy
            </Typography>
          </Box>
        </ParallaxLayer>

        <ParallaxLayer
          offset={isMobile ? 3.5 : 5}
          style={{
            background: "#f0efe5",
          }}
        >
          <Footer parallaxRef={parallaxRef} />
        </ParallaxLayer>
      </Parallax>

      <GalleryModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        images={images}
      />
    </Box>
  );
}

export default EternalRythm;
