import React, { useRef, useState } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/system";
import emailjs from "@emailjs/browser";

function Form({ onClose }) {
  const form = useRef();
  const [successMessage, setSuccessMessage] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_dg6qofr", "template_lecdpn7", form.current, {
        publicKey: "QtBbcadyOSyHAP8UV",
      })
      .then(
        () => {
          setSuccessMessage(true);
          setTimeout(() => {
            setSuccessMessage(false);
            onClose();
          }, 3000);
        },
        (error) => {
          console.log("FAILED...", error.text);
          alert("Your message could not be sent. Please try again later.");
        }
      );
  };

  const WhiteTextField = styled(TextField)({
    "& label": {
      color: "rgba(255, 255, 255, 0.4)",
    },
    "& label.Mui-focused": {
      color: "rgba(255, 255, 255, 0.4)",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "rgba(255, 255, 255, 0.4)",
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: "rgba(255, 255, 255, 0.4)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "rgba(255, 255, 255, 0.4)",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(255, 255, 255, 0.4)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(255, 255, 255, 0.4)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(255, 255, 255, 0.4)",
      },
      color: "white",
    },
    "& .MuiInputBase-input": {
      color: "white",
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <form
        style={{
          background: "#4e202a",
          color: "white",
          width: "400px",
          height: "auto",
          padding: "30px",
          position: "relative",
          borderRadius: "5px",
          margin: "0 20px",
        }}
        ref={form}
        onSubmit={sendEmail}
      >
        <Link to="/" onClick={onClose}>
          <img
            src="/assets/close-white.png"
            alt=""
            style={{
              position: "absolute",
              top: "20px",
              right: "20px",
              width: "50px",
              cursor: "pointer",
            }}
          />
        </Link>

        {successMessage && (
          <Box
            sx={{
              backgroundColor: "rgba(0, 150, 0, 0.9)",
              color: "white",
              padding: "10px",
              borderRadius: "5px",
              position: "absolute",
              bottom: "-50px",
              zIndex: "100",
              width: "calc(100% - 80px)",
              textAlign: "center",
            }}
          >
            Your inquiry is sent successfully!
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: "20px",
          }}
        >
          <Box>
            <Typography variant="h2">Send an</Typography>
            <Typography variant="h2" sx={{ fontFamily: "Ogg Italic" }}>
              inquiry
            </Typography>
          </Box>

          <Box>
            <Typography variant="body2">
              Fill this form and we’ll get in contact as soon as possible!
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "20px",
            }}
          >
            <WhiteTextField
              label="Your name"
              variant="standard"
              name="user_name"
              required
            />
            <WhiteTextField
              label="Enter your email"
              variant="standard"
              name="user_email"
              required
            />
          </Box>

          <Box
            sx={{
              alignSelf: "flex-end",
              paddingTop: "20px",
            }}
          >
            <Button
              sx={{
                background: "white",
                color: "black",
                padding: "10px 8px",
                borderRadius: "60px",
                width: "180px",
                border: "1px solid white",

                "&:hover": {
                  border: "1px solid white",
                  color: "white",
                  background: "none",
                },
              }}
              type="submit"
            >
              send
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
}

export default Form;
