import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { BrowserView, MobileView } from "react-device-detect";

function About({ className }) {
  const [showFullContent, setShowFullContent] = useState(false);

  const toggleShowFullContent = () => {
    setShowFullContent(!showFullContent);
  };

  return (
    <Box className={`homepage-about ${className}`} padding={"16px 0px"}>
      <BrowserView>
        <Box className="sections-title">
          <Typography variant="body1" color="black">
            // 03
          </Typography>
          <Typography variant="body1">ABOUT ME</Typography>
          <Typography variant="body1">DESIGNER & ARTIST</Typography>
          <Typography variant="body1">WHO AM I</Typography>
        </Box>
      </BrowserView>

      <MobileView>
        <Box className="sections-title">
          <Box textAlign={"left"}>
            <Typography variant="body1" color="black">
              // 03
            </Typography>
            <Typography variant="body1">ABOUT ME</Typography>
          </Box>

          <Box textAlign={"right"}>
            <Typography variant="body1">DESIGNER & ARTIST</Typography>
            <Typography variant="body1">WHO AM I</Typography>
          </Box>
        </Box>
      </MobileView>

      <Box className="about-content">
        <Typography variant="h2" flex={1.1}>
          A COLLABORATIVE JOURNEY OF CREATION AND EXPERIMENTATION
        </Typography>

        <Box className="about-info" flex={1}>
          <Typography variant="body1">ABOUT HABIBA SAWAF</Typography>
          <Typography variant="body1">
            Habiba Sawaf is a visual designer from Cairo, Egypt. She earned her
            Graphic Design degree from the German University in Cairo's faculty
            of Applied Arts & Sciences. Since her journey with design began, she
            has been fascinated by the possibilities of design and its
            intersections with diverse disciplines.
          </Typography>

          <Typography variant="body1">
            Drawing inspiration from her surroundings, Habiba finds the design
            process to be the most fulfilling aspect of her work. With a love
            for experimentation, Habiba finds endless inspiration in the vibrant
            streets and rich cultural fabric of Cairo. Her admiration for
            Cairo's cultural tapestry evolved into a deep appreciation for its
            traditional crafts.
          </Typography>

          <Typography className="show-more" variant="body1">
            Cairo is full of crafts with captivating stories, skilled artisans,
            and bustling workshops. It was at the intersection of design and
            crafts that she truly found her calling, where the focus shifted
            from mere aesthetics to a collaborative journey of creation and
            experimentation.
          </Typography>

          <Typography className="show-more" variant="body1">
            Since 2021, Habiba has been collaborating with Khayameya artisans.
            Initially a newcomer to Khayameya Street in Cairo, she embarked on
            projects together with the craftsmen, forging friendships through
            shared conversations over countless cups of tea and coffee. This
            collaborative process has evolved into a creative partnership that
            challenges both her as a designer and craftsmen to push boundaries
            and explore new realms of experimentation.
          </Typography>

          <Box className="about-info-awards">
            <Typography variant="body1">AWARDS</Typography>
            <Typography variant="body1">
              London International Creative Competition - LICC 2022
              Non-Professional (Official Selection of Shoot (Photo/Video) for
              Veiled Reverie Collection)
            </Typography>
            <Typography variant="body1">
              London International Creative Competition - LICC 2022
              Non-Professional (Official Selection of Shoot (Graphic) for Veiled
              Reverie Collection)
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default About;
