import React, { useState, useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";

function Craft({ className }) {
  const words = ["ORNATE", "ENTICING", "INTRICATE"];
  const [currentIndex, setCurrentIndex] = useState(0);
  const sectionRef = useRef(null);

  // Effect to handle automatic word changes
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((currentIndex) => (currentIndex + 1) % words.length);
    }, 2500); // Change word every 3000 milliseconds (3 seconds)

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [words.length]); // Depend on words.length to reset interval if words array changes

  return (
    <Box className={`craft-section padding ${className}`} ref={sectionRef}>
      <img src="/assets/brand/craft-circle.png" alt="craft-circle" />
      <Typography variant="body1">
        UNVEILING ARTISTRY:
        <br /> A JOURNEY INTO KHAYAMIYA
      </Typography>
      <Box className="craft-info">
        <Typography variant="h2">AN</Typography>
        <Typography variant="h2" className="craft-middle-word">
          {words[currentIndex]}
        </Typography>
        <Typography variant="h2">CRAFT</Typography>
      </Box>
    </Box>
  );
}

export default Craft;
