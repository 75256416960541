import React, { useState, useEffect, useRef } from "react";
import Vimeo from "@vimeo/player";
import { FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import { isMobile } from "react-device-detect";

function HomeVideo({ className, videoId }) {
  const vimeoPlayer = useRef(null);
  const playerInstance = useRef(null);
  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    playerInstance.current = new Vimeo(vimeoPlayer.current, {
      id: videoId,
      autoplay: true,
      controls: false,
      loop: true,
      responsive: true,
      muted: isMuted, // Autoplay requires muted to be set to true
    });

    const resizeVideo = () => {
      playerInstance.current.getVideoWidth().then((width) => {
        const aspectRatio = 9 / 16;
        const height = Math.round(width * aspectRatio);
        playerInstance.current.element.style.width = "100%";
        playerInstance.current.element.style.height = `${height}px`;
      });
    };

    resizeVideo(); // Initial resize
    window.addEventListener("resize", resizeVideo);

    return () => {
      playerInstance.current.destroy();
      window.removeEventListener("resize", resizeVideo);
    };
  }, [videoId]);

  useEffect(() => {
    if (playerInstance.current) {
      playerInstance.current.setMuted(isMuted);
    }
  }, [isMuted]);

  const toggleMute = () => {
    setIsMuted((prevIsMuted) => !prevIsMuted);
  };

  return (
    <div className={`homepage-video ${className}`}>
      <div ref={vimeoPlayer} />
      <button
        onClick={toggleMute}
        className="mute-toggle-button"
        style={{
          marginRight: isMobile ? "33%" : "",
          bottom: isMobile ? "5px" : "",
        }}
      >
        {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
      </button>
    </div>
  );
}

export default HomeVideo;
