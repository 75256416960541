import React from "react";
import { Box, Typography } from "@mui/material";

function Locations({ className }) {
  return (
    <Box
      className={`locations-section ${className}`}
      sx={{
        position: "relative",
        height: "100vh",
        marginTop: "40px",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          position: "relative",
          margin: "auto",

          color: "white",
          fontSize: "1rem",
        }}
      >
        30° 2’56.2266” N 31.15’28.4328” W (KHAYAMEYA STREET/ CAIRO, EGYPT)
      </Typography>
    </Box>
  );
}

export default Locations;
