import React from "react";
import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { isMobile } from "react-device-detect";

function Explore({ className }) {
  const text =
    "EMBARK ON A JOURNEY THROUGH THE RICH TAPESTRY OF KHAYAMIYA, AN ANCIENT CRAFT BORN IN CAIRO THAT ONCE ADORNED TENTS AND PAVILIONS, NOW TEETERING ON THE BRINK OF EXTINCTION".split(
      " "
    );

  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5, // Adjust threshold as needed
  });

  return (
    <Box
      className={`homepage-explore ${className}`}
      sx={{
        position: "relative",
        height: "30vh",
        overflow: "hidden",
      }}
      ref={ref}
    >
      <Box className="hero-title" width={isMobile ? "80%" : "60%"}>
        <Typography
          variant="body1"
          sx={{ color: "#6B6B6B", textAlign: "center" }}
        >
          {text.map((el, i) => (
            <motion.span
              key={i}
              variants={variants}
              initial={inView ? "visible" : "hidden"}
              animate={inView ? "visible" : "hidden"}
              transition={{
                duration: 0.05, // Decreased duration for faster typing animation
                delay: inView ? i / 20 : 0, // Adjusted delay for smoother animation
              }}
            >
              <span style={{ fontSize: isMobile ? "11px" : "14px" }}>
                {el}{" "}
              </span>
            </motion.span>
          ))}
        </Typography>
      </Box>
    </Box>
  );
}

export default Explore;
