import React from "react";
import { motion } from "framer-motion";
import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { isMobile } from "react-device-detect";

function Card({ index, props }) {
  return (
    <motion.div
      className="card"
      initial={{
        opacity: 0,
        // if odd index card,slide from right instead of left
        x: index % 2 === 0 ? 50 : -50,
      }}
      whileInView={{
        opacity: 1,
        x: 0, // Slide in to its original position
        transition: {
          duration: 1, // Animation duration
        },
      }}
      viewport={{ once: true }}
    >
      <Link to={props.link} style={{ textDecoration: "none" }}>
        <Box
          sx={{
            width: isMobile ? "100%" : "420px",
            color: "black",
          }}
          className="collection-card"
        >
          <img src={props.image} alt="" style={{ width: "100%" }} />
          <Typography variant="body1" sx={{ textAlign: "left" }}>
            {props.collectionNumber}
          </Typography>
          <Typography
            variant="h5"
            sx={{ textAlign: "left", textDecoration: "none" }}
          >
            {props.collectionName}
          </Typography>

          <Box className="collection-card-description">
            <Typography variant="body1">{props.description}</Typography>

            <Typography
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginTop: " 5px",
              }}
            >
              Discover More{" "}
              <ArrowForwardIcon sx={{ fontSize: "16px", marginLeft: "6px" }} />
            </Typography>
          </Box>
        </Box>
      </Link>
    </motion.div>
  );
}

export default Card;
