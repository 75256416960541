import React from "react";
import { Box, Typography } from "@mui/material";
import Card from "./Card";
import { BrowserView, MobileView } from "react-device-detect";
import { useSwipeable } from "react-swipeable";
import { ArrowForwardIos, Description } from "@mui/icons-material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function Collections() {
  const handlers = useSwipeable({
    onSwipedLeft: () => scrollRight(),
    onSwipedRight: () => scrollLeft(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const scrollLeft = () => {
    document
      .getElementById("card-container")
      .scrollBy({ left: -window.innerWidth, behavior: "smooth" });
  };

  const scrollRight = () => {
    document
      .getElementById("card-container")
      .scrollBy({ left: window.innerWidth, behavior: "smooth" });
  };

  return (
    <Box
      id="collections"
      className="homepage-collections"
      sx={{ height: "auto" }}
    >
      <BrowserView>
        <Box className="sections-title">
          <Typography variant="body1" color="black">
            // 01
          </Typography>
          <Typography variant="body1">PROJECTS</Typography>
          <Typography variant="body1">COLLECTIONS</Typography>
          <Typography variant="body1">THE ART</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "5%",
            flexWrap: "wrap",
          }}
          className="collection-card-container"
        >
          <Card
            index={0}
            props={{
              image: "/assets/collections/item-1.png",
              collectionName: "Veiled Reverie",
              collectionNumber: "[Collection 1]",
              description:
                "An exploration of the craft of Khayamiya through the medium of artistic expression.",
              link: "/veiledreverie",
            }}
          />
          <Card
            index={1}
            props={{
              image: "/assets/collections/item-2.png",
              collectionName: "Eternal Rythm",
              collectionNumber: "[Collection 2]",
              description:
                "Harmony transcends matter. The eye is a witness of the bond between the stitches and the chords that play to the beat of the moving particles.",
              link: "/eternalrythm",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "5%",
          }}
          className="collection-card-container"
        >
          <Card
            index={2}
            props={{
              image: "/assets/collections/item-3.png",
              collectionName: "Blossoming Traditions",
              collectionNumber: "[Collection 3]",
              description:
                "Where the ancient art of Khayameya seamlessly meets contemporary style.",
              link: "/blossomingtraditions",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "5%",
          }}
          className="collection-card-container"
        >
          <Card
            index={3}
            props={{
              image: "/assets/collections/item-4.png",
              collectionName: "Publication: Art of Khayameya",
              collectionNumber: "[Collection 4]",
              description:
                "An ancient craft born in Cairo that once adorned tents and pavilions, is now teetering on the brink of extinction.",
              link: "/publication",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            marginTop: "5%",
            marginBottom: "5%",
          }}
          className="collection-card-container"
        >
          <Card
            index={4}
            props={{
              image: "/assets/collections/item-5.png",
              collectionName: "Flight Of Longing",
              collectionNumber: "[Collection 5]",
              description:
                "Inspired by the iconic folk song 'يا اما حمامى سرح' this handcrafted Khayameya celebrates Egyptian traditional folklore.",
              link: "/flight-of-longing",
            }}
          />
        </Box>
      </BrowserView>

      <MobileView>
        <Box className="sections-title">
          <Box textAlign={"left"}>
            <Typography variant="body1" color="black">
              // 01
            </Typography>
            <Typography variant="body1">PROJECTS</Typography>
          </Box>
          <Box textAlign={"right"}>
            <Typography variant="body1">COLLECTIONS</Typography>
            <Typography variant="body1">THE ART</Typography>
          </Box>
        </Box>
        <Box
          id="card-container"
          {...handlers}
          sx={{
            display: "flex",
            overflowX: "auto",
            scrollSnapType: "x mandatory",
            WebkitOverflowScrolling: "touch",
            scrollBehavior: "smooth",
          }}
          className="collection-card-container"
        >
          {[
            {
              index: 0,
              props: {
                image: "/assets/collections/item-1.png",
                collectionName: "Veiled Reverie",
                collectionNumber: "[Collection 1]",
                description:
                  "An exploration of the craft of Khayamiya through the medium of artistic expression.",
                link: "/veiledreverie",
              },
            },
            {
              index: 1,
              props: {
                image: "/assets/collections/item-2.png",
                collectionName: "Eternal Rythm",
                collectionNumber: "[Collection 2]",
                description:
                  "Harmony transcends matter. The eye is a witness of the bond between the stitches and the chords that play to the beat of the moving particles.",
                link: "/eternalrythm",
              },
            },
            {
              index: 2,
              props: {
                image: "/assets/collections/item-3.png",
                collectionName: "Blossoming Traditions",
                collectionNumber: "[Collection 3]",
                description:
                  "Where the ancient art of Khayameya seamlessly meets contemporary style.",
                link: "/blossomingtraditions",
              },
            },
            {
              index: 3,
              props: {
                image: "/assets/collections/item-4.png",
                collectionName: "Publication: Art of Khayameya",
                collectionNumber: "[Collection 4]",
                description:
                  "An ancient craft born in Cairo that once adorned tents and pavilions, is now teetering on the brink of extinction.",
                link: "/publication",
              },
            },
            {
              index: 4,
              props: {
                image: "/assets/collections/item-5.png",
                collectionName: "Flight Of Longing",
                collectionNumber: "[Collection 5]",
                description:
                  "Inspired by the iconic folk song 'يا اما حمامى سرح' this handcrafted Khayameya celebrates Egyptian traditional folklore.",
                link: "/flight-of-longing",
              },
            },
          ].map((card) => (
            <Box
              key={card.index}
              sx={{
                flex: "0 0 100%",
                scrollSnapAlign: "start",
              }}
            >
              <Card index={card.index} props={card.props} />

              {card.index === 0 && (
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "5%",
                    gap: "5px",
                    zIndex: 100,
                  }}
                >
                  Swipe to see more{" "}
                  <ArrowForwardIcon sx={{ fontSize: "16px" }} />
                </Typography>
              )}
            </Box>
          ))}
        </Box>
      </MobileView>
    </Box>
  );
}

export default Collections;
