import React, { useRef } from "react";
import { Box, Typography } from "@mui/material";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import Footer from "./Footer";
import { BrowserView, MobileView } from "react-device-detect";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import HomeVideo from "./HomeVideo";

function Pigeon() {
  const parallaxRef = useRef(null);

  const images = [
    "/assets/pigeon/01.webp",
    "/assets/pigeon/02.webp",
    "/assets/pigeon/03.webp",
    "/assets/pigeon/04.webp",
    "/assets/pigeon/05.webp",
    "/assets/pigeon/06.webp",
    "/assets/pigeon/07.webp",
  ];
  return (
    <Box className="collection-page-padding">
      <Parallax pages={isMobile ? 4.9 : 12} ref={parallaxRef}>
        <ParallaxLayer offset={0} factor={1}>
          <Link to="/" style={{ marginLeft: "70%" }}>
            <img src="/assets/close.svg" alt="" />
          </Link>

          <img
            src="/assets/pigeon/title.webp"
            alt=""
            style={{ width: isMobile ? "100%" : "50%", marginTop: "20px" }}
          />

          <Typography
            variant="h5"
            sx={{ fontFamily: "Editorial" }}
            fontSize={isMobile ? "1rem" : "1.5rem"}
            marginTop={isMobile ? "100px" : "40px"}
          >
            A TAPESTRY INSPIRED BY THE EGYPTIAN <br /> TRADITIONAL FOLKLORE SONG
          </Typography>
        </ParallaxLayer>

        <MobileView>
          <ParallaxLayer offset={isMobile ? 0.7 : 1} factor={1}>
            <img
              src="/assets/pigeon/concept-mobile.webp"
              alt=""
              style={{ width: "100%" }}
            />
          </ParallaxLayer>
        </MobileView>

        <BrowserView>
          <ParallaxLayer offset={isMobile ? 0.5 : 1} factor={1}>
            <img
              src="/assets/pigeon/concept.png"
              alt=""
              style={{ width: "100%" }}
            />
          </ParallaxLayer>
        </BrowserView>

        <ParallaxLayer
          offset={1.4}
          sticky={isMobile ? false : { start: 2, end: 9 }}
          style={{
            textAlign: "left",
            width: "28%",
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            zIndex: "-5",
            padding: "0 16px",
          }}
          className="collection-info"
        >
          <Typography
            className="collection-info-title"
            variant="body1"
            fontWeight={600}
          >
            INFO
          </Typography>
          <Typography variant="body1">
            Inspired by the iconic folk song 'يا اما حمامى سرح' this handcrafted
            Khayameya celebrates Egyptian traditional folklore. Titled "Flight
            of Longing”, this piece dives into the depths of longing and
            tradition as this timeless melody echoes through generations.
          </Typography>
          <Typography variant="body1">
            Central to the design is the silhouette of the Borg Hammam, standing
            steadfast as a bridge connecting the past to the present. Yet,
            amidst its towering presence, pigeons take flight, their wings
            weaving through the song's verses carrying the whispers of longing.
            This tapestry is more than just a piece of art—it's a portal to the
            soul of Egyptian culture where folklore dances through the ages, and
            where the song of a single pigeon whispers tales of resilience,
            nostalgia, and the eternal dance of the human heart.
          </Typography>
        </ParallaxLayer>

        <ParallaxLayer
          offset={1.9}
          style={{
            display: isMobile ? "" : "none",
            justifyContent: "center",
          }}
        >
          <Carousel
            showArrows={true}
            showIndicators={false}
            dots={true}
            infiniteLoop={true}
            showThumbs={false}
            autoPlay={true}
            emulateTouch={true}
            showStatus={true}
            style={{ padding: "0 16px" }}
          >
            {images.map((image, index) => (
              <div key={index}>
                <img
                  style={{ width: "95%", height: "500px", objectFit: "cover" }}
                  src={image}
                  alt=""
                />
              </div>
            ))}
          </Carousel>
        </ParallaxLayer>

        <BrowserView>
          <ParallaxLayer
            offset={2}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img src="/assets/pigeon/01.webp" alt="" />
          </ParallaxLayer>

          <ParallaxLayer
            offset={3}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img src="/assets/pigeon/02.webp" alt="" />
          </ParallaxLayer>

          <ParallaxLayer
            offset={4}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img src="/assets/pigeon/03.webp" alt="" />
          </ParallaxLayer>

          <ParallaxLayer
            offset={5}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img src="/assets/pigeon/04.webp" alt="" />
          </ParallaxLayer>

          <ParallaxLayer
            offset={6}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img src="/assets/pigeon/05.webp" alt="" />
          </ParallaxLayer>

          <ParallaxLayer
            offset={7}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img src="/assets/pigeon/06.webp" alt="" />
          </ParallaxLayer>

          <ParallaxLayer
            offset={8}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img src="/assets/pigeon/07.webp" alt="" />
          </ParallaxLayer>
        </BrowserView>

        <ParallaxLayer
          offset={isMobile ? 2.5 : 9}
          speed={0.5}
          style={{
            width: "50%",
            height: "100%",
            marginLeft: "50%",
          }}
        >
          <HomeVideo videoId="949594027" />
        </ParallaxLayer>

        <ParallaxLayer offset={isMobile ? 3.2 : 10} speed={0.5}>
          <img
            style={{ width: "100%", background: "#fffef3" }}
            src="/assets/pigeon/sketches.webp"
            alt=""
          />
        </ParallaxLayer>

        <ParallaxLayer
          offset={isMobile ? 3.9 : 11}
          style={{
            background: "#f0efe5",
          }}
        >
          <Footer parallaxRef={parallaxRef} />
        </ParallaxLayer>
      </Parallax>
    </Box>
  );
}

export default Pigeon;
