import React from "react";
import { AppBar, Toolbar, Box, Button, Typography } from "@mui/material";
import { Link } from "react-scroll";
import { isMobile } from "react-device-detect";

function Navbar({ parallaxRef }) {
  const scrollToLayer = (layerIndex) => {
    parallaxRef.current.scrollTo(layerIndex);
  };

  return (
    <AppBar
      position="static"
      sx={{ background: "none", color: "black", boxShadow: "none" }}
      className="navbar"
    >
      <Toolbar>
        {/* Logo image in the middle */}
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            marginRight: "12%",
          }}
        >
          <Link to="/" spy={true} smooth={true}>
            <img className="nav-logo" src="/assets/brand/logo.svg" alt="Logo" />
          </Link>
        </Box>

        {/* Navigation links on the left */}
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            justifyContent: "start",
            alignItems: "center",
          }}
          className="nav-links"
        >
          <Button
            color="inherit"
            onClick={() => scrollToLayer(15)}
            className="nav-link"
          >
            Collections{" /"}
          </Button>
          <Button
            color="inherit"
            onClick={() => scrollToLayer(18.6)}
            className="nav-link"
          >
            About{" /"}
          </Button>
          <Button
            color="inherit"
            onClick={() => scrollToLayer(17.6)}
            className="nav-link"
          >
            Exhibitions
          </Button>
        </Box>

        {/* Link on the right */}
        <Box sx={{ display: "flex", flexGrow: 1, justifyContent: "flex-end" }}>
          <Typography
            variant="body1"
            fontSize={isMobile ? "0.6rem" : "0.8rem"}
            color="#6B6B6B"
            sx={{ textAlign: "right" }}
          >
            BASED IN CAIRO, EGYPT <br /> GRAPHIC DESIGNER & ARTIST
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
