import React from "react";
import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";

function Hero({ className }) {
  const title = "AN EXPLORATION, REFLECTION, AND EVOLUTION".split(" ");

  return (
    <Box className={`hero-section padding ${className}`}>
      <Box>
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            paddingTop: "20px",
          }}
        >
          {title.map((el, i) => (
            <motion.span
              initial={{ opacity: 0 }} // Start slightly below its final position
              animate={{ opacity: 1 }} // Move to its final position and fade in
              transition={{
                duration: 0.25,
                delay: i / 10,
              }}
              key={i}
            >
              {el}{" "}
            </motion.span>
          ))}
        </Typography>
      </Box>

      <Box className="hero-info">
        <Typography variant="body2">EGYPTIAN APPLIQUE - KHAYAMEYA</Typography>
      </Box>
    </Box>
  );
}

export default Hero;
