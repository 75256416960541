import React, { useRef, useState } from "react";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import { Box, Modal, Typography } from "@mui/material";
import Hero from "./Hero";
import HomeVideo from "./HomeVideo";
import Explore from "./Explore";
import Locations from "./Locations";
import Craft from "./Craft";
import Exhibitions from "./Exhibitions";
import About from "./About";
import Footer from "./Footer";
import Navbar from "./Navbar";
import Collections from "./Collections";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { isMobile } from "react-device-detect";
import Form from "./Form";

function Home() {
  const theme = createTheme({
    typography: {
      fontFamily: "Suiss Regular",
    },
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const parallaxRef = useRef(null);

  return (
    <Box>
      <ThemeProvider theme={theme}>
        <Parallax pages={isMobile ? 18.6 : 20.2} ref={parallaxRef}>
          <ParallaxLayer offset={0} speed={0.5} sticky={{ start: 0, end: 4 }}>
            <Hero />
          </ParallaxLayer>

          <ParallaxLayer offset={0} speed={0.5} sticky={{ start: 0, end: 4 }}>
            <Navbar parallaxRef={parallaxRef} />
          </ParallaxLayer>

          <ParallaxLayer
            offset={1}
            speed={1}
            sticky={{ start: 1, end: 4 }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="/assets/explore-slides/slide1.webp"
              alt="Veiled Reverie"
              style={{ width: isMobile ? "90%" : "50%", margin: "auto" }}
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={2}
            speed={1}
            sticky={{ start: 2, end: 4 }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="/assets/explore-slides/slide2.webp"
              alt="Veiled Reverie"
              style={{ width: isMobile ? "80%" : "30%", margin: "auto" }}
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={3}
            speed={1}
            sticky={{ start: 3, end: 4 }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="/assets/explore-slides/slide3.webp"
              alt="Veiled Reverie"
              style={{ width: isMobile ? "70%" : "20%", margin: "auto" }}
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={4}
            speed={1}
            sticky={{ start: 4, end: 4 }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="/assets/explore-slides/slide4.webp"
              alt="Veiled Reverie"
              style={{ width: isMobile ? "90%" : "60%", margin: "auto" }}
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={5}
            speed={0.5}
            style={{
              width: isMobile ? "300%" : "",
              height: isMobile ? "300%" : "",
              marginLeft: isMobile ? "-100%" : "",
            }}
          >
            <HomeVideo videoId="945968879" />
          </ParallaxLayer>

          <ParallaxLayer offset={6} speed={0.5}>
            <Explore />
          </ParallaxLayer>

          <ParallaxLayer
            offset={6.5}
            speed={1}
            style={{
              backgroundImage: "url(/assets/locations-slides/slide4.webp)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "50%",
              position: "absolute",
              left: "0 ",
            }}
            className="locations-slide"
            sticky={{ start: 6.5, end: 13 }}
          ></ParallaxLayer>

          <ParallaxLayer
            offset={7}
            speed={1}
            style={{
              backgroundImage: "url(/assets/locations-slides/slide5.webp)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "50%",
              position: "absolute",
              right: "0 ",
              marginLeft: "50%",
            }}
            className="locations-slide"
            sticky={{ start: 7, end: 13 }}
          ></ParallaxLayer>

          <ParallaxLayer
            offset={8}
            speed={1}
            style={{
              backgroundImage: "url(/assets/locations-slides/slide3.webp)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "50%",
              position: "absolute",
              left: "0 ",
            }}
            className="locations-slide"
            sticky={{ start: 8, end: 13 }}
          ></ParallaxLayer>

          <ParallaxLayer
            offset={9}
            speed={1}
            style={{
              backgroundImage: "url(/assets/locations-slides/slide1.webp)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "50%",
              position: "absolute",
              right: "0 ",
              marginLeft: "50%",
            }}
            className="locations-slide"
            sticky={{ start: 9, end: 13 }}
          ></ParallaxLayer>

          <ParallaxLayer
            offset={10}
            speed={1}
            style={{
              backgroundImage: "url(/assets/locations-slides/slide2.webp)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "50%",
              position: "absolute",
              left: "0 ",
            }}
            className="locations-slide"
            sticky={{ start: 10, end: 13 }}
          ></ParallaxLayer>

          <ParallaxLayer
            offset={11}
            speed={1}
            style={{
              backgroundImage: "url(/assets/locations-slides/slide6.webp)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "50%",
              position: "absolute",
              right: "0 ",
              marginLeft: "50%",
            }}
            className="locations-slide"
            sticky={{ start: 11, end: 13 }}
          ></ParallaxLayer>

          <ParallaxLayer
            offset={12}
            speed={1}
            style={{
              backgroundImage: "url(/assets/locations-slides/slide7.webp)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "50%",
              position: "absolute",
              left: "0 ",
            }}
            className="locations-slide"
            sticky={{ start: 12, end: 13 }}
          ></ParallaxLayer>

          <ParallaxLayer
            offset={13}
            speed={1}
            style={{
              backgroundImage: "url(/assets/locations-slides/slide8.webp)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "50%",
              position: "absolute",
              right: "0 ",
              marginLeft: "50%",
            }}
            className="locations-slide"
            sticky={{ start: 13, end: 13 }}
          ></ParallaxLayer>

          <ParallaxLayer offset={7} speed={0.5} sticky={{ start: 7, end: 13 }}>
            <Locations />
          </ParallaxLayer>

          <ParallaxLayer offset={14} speed={0.5}>
            <Craft />
          </ParallaxLayer>

          <ParallaxLayer offset={15} speed={0.5} factor={2} id="collections">
            <Collections />
          </ParallaxLayer>

          <ParallaxLayer
            offset={isMobile ? 15.9 : 17.8}
            speed={0.5}
            id="exhibitions"
          >
            <Exhibitions />
          </ParallaxLayer>

          <ParallaxLayer offset={isMobile ? 16.9 : 18.9} speed={0.5} id="about">
            <About />
          </ParallaxLayer>

          <ParallaxLayer offset={isMobile ? 17.9 : 19.6} speed={0.5}>
            <Footer parallaxRef={parallaxRef} />
          </ParallaxLayer>
        </Parallax>
      </ThemeProvider>

      <Typography
        variant="h5"
        sx={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          padding: "0.5rem",
          color: "black",
          textDecoration: "underline",
          fontFamily: "Editorial Italic",
          cursor: "pointer",
        }}
        onClick={handleOpenModal}
      >
        Inquire Now
      </Typography>

      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Form onClose={handleCloseModal} />
      </Modal>
    </Box>
  );
}

export default Home;
