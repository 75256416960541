// GalleryModal.jsx
import React from "react";
import Modal from "react-modal";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const GalleryModal = ({ isOpen, onRequestClose, images }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel="Gallery Modal"
    style={{
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        height: "auto",
        backgroundColor: "transparent",
        border: "none",
      },
      overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.75)",
      },
    }}
  >
    <Carousel
      showArrows={true}
      showIndicators={true}
      infiniteLoop={true}
      dots={true}
      showThumbs={true}
      autoPlay={true}
      emulateTouch={true}
      showStatus={false}
      emulateScroll={true}
    >
      {images.map((image, index) => (
        <div key={index}>
          <img
            style={{ width: "100%", height: "auto", objectFit: "cover" }}
            src={image}
            alt={`Gallery img ${index + 1}`}
          />
        </div>
      ))}
    </Carousel>
  </Modal>
);

export default GalleryModal;
