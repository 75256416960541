import React, { useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { isMobile } from "react-device-detect";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BrowserView } from "react-device-detect";

import GalleryModal from "./GalleryModal"; // Import the GalleryModal component

function Publication() {
  const parallaxRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const images = [
    "/assets/publication/01.webp",
    "/assets/publication/02.webp",
    "/assets/publication/03.webp",
    "/assets/publication/04.webp",
    "/assets/publication/05.webp",
    "/assets/publication/06.webp",
    "/assets/publication/07.webp",
    "/assets/publication/08.webp",
    "/assets/publication/09.webp",
    "/assets/publication/10.webp",
    "/assets/publication/11.webp",
  ];

  return (
    <Box className="collection-page-padding">
      <Parallax pages={isMobile ? 3.7 : 9} ref={parallaxRef}>
        <ParallaxLayer offset={0} factor={0.5}>
          <Box className="collection-header">
            <Typography
              className="h2-variant"
              variant="h2"
              sx={{ padding: "0 16px" }}
            >
              PUBLICATION: ART <br />
              OF KHAYAMEYA
            </Typography>
            <Link to="/">
              <img src="/assets/close.svg" alt="" />
            </Link>
          </Box>

          <Box className="collection-header-info">
            <Typography variant="body1">[EDITORIAL 1]</Typography>

            <Typography
              onClick={openModal}
              variant="body1"
              sx={{
                display: "flex",
                alignItems: "center",
                color: "black",
                cursor: "pointer",
              }}
            >
              VIEW GALLERY <ArrowForwardIcon />
            </Typography>
          </Box>
        </ParallaxLayer>

        <ParallaxLayer
          offset={isMobile ? 0.7 : 0.8}
          sticky={isMobile ? false : { start: 0.8, end: 19 }}
          style={{
            textAlign: "left",
            width: "28%",
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            zIndex: "-5",
            padding: "0 16px",
          }}
          className="collection-info"
        >
          <Typography
            className="collection-info-title"
            variant="body1"
            fontWeight={600}
          >
            INFO
          </Typography>
          <Typography variant="body1">
            An ancient craft born in Cairo that once adorned tents and
            pavilions, is now teetering on the brink of extinction. Amidst this
            precarious state, dedicated craftsmen persevere, weaving intricate
            beauty with the hope that Khayamiya’s artistry will once again
            flourish.
          </Typography>
          <Typography variant="body1">
            Titled "Beauty in What is Hidden," this publication explores
            Khayamiya's layers, featuring French folds with poetic verses
            written alongside the craftsmen and covers handmade from the
            artisans' fabric, 'Dak.' The outcome pushes the viewer to see
            through the craft and find beauty in all its aspects, and see it
            from the eyes of the artisan who sews the needle with meticulous
            detailing and channeled years of practice, experience, and
            inspiration into his work.
          </Typography>
        </ParallaxLayer>

        <ParallaxLayer
          offset={isMobile ? 1.2 : 2}
          style={{
            display: isMobile ? "" : "none",
            justifyContent: "center",
          }}
        >
          <Carousel
            showArrows={true}
            showIndicators={false}
            dots={true}
            infiniteLoop={true}
            showThumbs={false}
            autoPlay={true}
            emulateTouch={true}
            showStatus={true}
            style={{ padding: "0 16px" }}
          >
            {images.map((image, index) => (
              <div key={index}>
                <img
                  style={{ width: "95%", height: "500px", objectFit: "cover" }}
                  src={image}
                  alt=""
                />
              </div>
            ))}
          </Carousel>
        </ParallaxLayer>

        <BrowserView>
          <ParallaxLayer
            offset={0.8}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/publication/01.webp"
              alt=""
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={1}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/publication/02.webp"
              alt=""
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={1.8}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/publication/03.webp"
              alt=""
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={2}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/publication/04.webp"
              alt=""
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={2.8}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/publication/05.webp"
              alt=""
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={3}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/publication/06.webp"
              alt=""
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={3.8}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/publication/07.webp"
              alt=""
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={4}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/publication/08.webp"
              alt=""
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={4.8}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/publication/09.webp"
              alt=""
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={5}
            speed={0.5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <img
              style={{ width: "60%", height: "60%", objectFit: "cover" }}
              src="/assets/publication/10.webp"
              alt=""
            />
          </ParallaxLayer>
        </BrowserView>

        <ParallaxLayer
          offset={isMobile ? 1.9 : 6}
          style={{
            textAlign: "left",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: isMobile ? "100%" : "40%",
              padding: isMobile ? "5%" : "16px",
              marginLeft: isMobile ? "" : "20%",
              display: "flex",
              flexDirection: "column",
              gap: "30px",
            }}
          >
            <Typography
              variant="body1"
              fontWeight={600}
              className="collection-info-title"
            >
              THE POEM
            </Typography>

            <img
              src="/assets/publication/elements.png"
              alt="elements"
              style={{ width: isMobile ? "100%" : "140%" }}
            />
          </Box>
        </ParallaxLayer>

        <ParallaxLayer
          offset={isMobile ? 2.3 : 7}
          style={{
            textAlign: "left",
            display: "flex",
            justifyContent: "center",
            padding: "16px",
          }}
        >
          <Box
            sx={{
              width: isMobile ? "100%" : "50%",
              padding: isMobile ? "5%" : "16px",
              marginLeft: isMobile ? "" : "30%",
              display: "flex",
              flexDirection: "column",
              gap: "30px",
            }}
          >
            <Typography
              variant="body1"
              fontWeight={600}
              className="collection-info-title"
            >
              CREDITS
            </Typography>
            <Typography variant="body1">
              Credits to Dr. Seif El Rashidi for his input and images archive.
              This project was done under the supervision of Amparo Baquerizas
            </Typography>
          </Box>
        </ParallaxLayer>

        <ParallaxLayer
          offset={isMobile ? 2.8 : 8}
          style={{
            background: "#f0efe5",
          }}
        >
          <Footer parallaxRef={parallaxRef} />
        </ParallaxLayer>
      </Parallax>

      <GalleryModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        images={images}
      />
    </Box>
  );
}

export default Publication;
